import React, { useState } from "react";
import { OrganizationContextProvider } from "./organizationContext";
const OrganizationContextContainer = ({ children }) => {
  const [organizationId, setOrganizationId] = useState(null);
  return (
    <OrganizationContextProvider
      value={{
        organizationId,
        setOrganizationId,
      }}
    >
      {children}
    </OrganizationContextProvider>
  );
};

export default OrganizationContextContainer;
