import React from "react";
import styles from "./spinner.module.css";
import { COLORS } from "../../enums";

const Spinner = ({ lightBg, blueBg, color }) => {
  const colorStyles = {
    background: color
      ? color
      : lightBg
      ? COLORS.PICKLED_BLUEWOOD
      : COLORS.AZURE_RADIANCE,
  };

  return (
    <div className={styles.spinner}>
      <div className={styles.bounce1} style={colorStyles} />
      <div className={styles.bounce2} style={colorStyles} />
      <div className={styles.bounce3} style={colorStyles} />
    </div>
  );
};

export default Spinner;
