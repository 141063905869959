import { get } from "lodash-es";
import errorObject from "./ErrorObject";

export const errorResponseHandler = (error) => {
  if (
    error &&
    (get(error, "status") === 401 ||
      get(error, "status") === 403 ||
      get(error, "response.status") === 401 ||
      get(error, "response.status") === 403)
  ) {
    localStorage.removeItem("user");
    window.sessionStorage.removeItem("token");
    // const path = window.location.origin;
    error.message = "You're UnAuthorized, check login or user privileges";
    // window.location.assign(path + '/login');
  }

  if (
    error &&
    (get(error, "status") === 404 || get(error, "response.status") === 404)
  ) {
    error.message = "Not Found";
    const path = window.location.origin;

    // window.location.assign(path + "/404");
  }

  if (
    error &&
    (get(error, "status") === 503 || get(error, "response.status")) === 503
  ) {
    error.message = "Internal Server Error";
    // const path = window.location.origin;

    // window.location.assign(path + '/500');
  }

  if (error) {
    throw errorObject(error);
  }
};

export function successResponseHandler(response) {
  const pageDetails = response.headers["x-pagination"]
    ? JSON.parse(response.headers["x-pagination"])
    : undefined;

  return {
    ...response,
    data: response.data,
    pageDetails,
  };
}
