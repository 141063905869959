import AppContainer from "./containers/appContainer/appContainer";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./styles.scss";
import "./colors.scss";
import AuthContextContainer from "./contexts/auth/authContextContainer";
import UserConfirmation from "./components/modals/LeaveModal";
import OrganizationContextContainer from "./contexts/Organization/organizationContextContainer";
import AudioContextContainer from "./contexts/audioContext/AudioContextContainer";
import QuestionStepContextContainer from "./contexts/questionStepContext/QuestionStepContextContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

function App() {
  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) =>
        UserConfirmation(message, callback)
      }
    >
      <AuthContextContainer>
        <OrganizationContextContainer>
          <AudioContextContainer>
            <QuestionStepContextContainer>
              <AppContainer />
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{
                  zIndex: "21000",
                }}
              />
            </QuestionStepContextContainer>
          </AudioContextContainer>
        </OrganizationContextContainer>
      </AuthContextContainer>
    </BrowserRouter>
  );
}

export default App;
