import React, { useState } from "react";
import { LanguagesContextProvider } from "./languageContext";
import { IntlProvider } from "react-intl";
import English from "../../languages/en.json";
import Chinese from "../../languages/zh.json";
import { updateUser } from "../../services/language.service";
import { LANGAUGES } from "../../enums";

const language = navigator.language;
let defaultMessages;

switch (language) {
  case LANGAUGES.ENGLISH:
    defaultMessages = English;
    break;
  case LANGAUGES.CHINESE:
    defaultMessages = Chinese;
    break;
  default:
    defaultMessages = English;
    break;
}

const LanguagesContextContainer = ({ children }) => {
  const [locale, setLocale] = useState(navigator.language);
  const [messages, setMessages] = useState(defaultMessages);

  const selectLanguage = async (e, user) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
    const updatedUser = await updateUser(user._id, newLocale);
    const localUser = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...localUser, language: updatedUser.language })
    );
    if (newLocale === LANGAUGES.ENGLISH) {
      setMessages(English);
    } else if (newLocale === LANGAUGES.CHINESE) {
      setMessages(Chinese);
    }
  };

  const isEnglish = () => {
    if (locale === LANGAUGES.ENGLISH) {
      return true;
    }
  };
  return (
    <LanguagesContextProvider
      value={{ locale, selectLanguage, setLocale, setMessages, isEnglish }}
    >
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LanguagesContextProvider>
  );
};

export default LanguagesContextContainer;
