import { useContext } from "react";
import { AuthContext } from "../../contexts/auth/authContext";

const RBAC = ({ allowedRoles, children }) => {
  const user = useContext(AuthContext).getUser();
  const access = allowedRoles.includes(user?.roleId.name);
  return access && children;
};

export default RBAC;
