import React, { useContext, useRef, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "./sidebar.module.scss";
import homeicon from "../../assets/images/Home.png";
import usericon from "../../assets/images/User.png";
import clienticon from "../../assets/images/Clients.png";
import deleteicon from "../../assets/images/DeleteXX.png";
import tagicon from "../../assets/images/tag.svg";
import {
  ROUTE_HOME,
  ROUTE_BIN,
  ROUTE_USERMANAGEMENT,
  ROUTE_GROUPS,
  ROUTE_ORGANIZATION_CONTENT,
  ROUTE_OFFICIAL_CONTENT,
  ROUTE_TAGS,
  ROUTE_QUESTIONNAIRE,
} from "../../constants";
import { AuthContext } from "../../contexts/auth/authContext";
import { LanguagesContext } from "../../contexts/languagesContext/languageContext";
import { FormattedMessage } from "react-intl";
import {
  getBackgroundColor,
  createImageFromInitials,
} from "../../helpers/questionSet";
import { ROLES } from "../../enums";
import RBAC from "../rbac/RBAC";
import $ from "jquery";
import { useLocation } from "react-router-dom";

const disabledStyle = {
  pointerEvents: "none",
  color: "#d3d3d3",
};

const Sidebar = () => {
  const authContext = useContext(AuthContext);

  const { locale, selectLanguage } = useContext(LanguagesContext);
  const user = authContext.getUser();
  const { connectedUsers } = authContext;
  const { name, imageUrl, roleId } = user;
  const generateRandomImageRef = useRef(
    createImageFromInitials(500, name, getBackgroundColor(roleId))
  );
  const organizationIdRef = useRef(null);
  const previousLink = useRef(null);
  const location = useLocation();

  organizationIdRef.current = useMemo(() => {
    const user = authContext.getUser();

    if (user.roleId?.name === ROLES.ORG_ADMIN) {
      return user._id;
    } else if (user.roleId?.name === ROLES.ORG_MEMBER) {
      return user.organizationId;
    } else {
      return null;
    }
  }, [authContext]);

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, [connectedUsers]);

  useEffect(() => {
    const user = authContext.getUser();
    if (previousLink.current) {
      previousLink.current.removeClass(classes["itemcontainer--active"]);
    }
    $(function () {
      $(`.${classes["itemcontainer"]} a[href="${location.pathname}"]`).addClass(
        `${classes["itemcontainer--active"]}`
      );
      previousLink.current = $(
        `.${classes["itemcontainer"]} a[href="${location.pathname}"]`
      );
    });

    if (location.pathname === `${ROUTE_ORGANIZATION_CONTENT}/${user._id}`) {
      $(function () {
        $(`.${classes["itemcontainer"]} a[href="/"]`).addClass(
          `${classes["itemcontainer--active"]}`
        );
        previousLink.current = $(`.${classes["itemcontainer"]} a[href="/"]`);
      });
    } else if (location.pathname === `${ROUTE_OFFICIAL_CONTENT}`) {
      $(function () {
        $(`.${classes["itemcontainer"]} a[href="/"]`).addClass(
          `${classes["itemcontainer--active"]}`
        );
        previousLink.current = $(`.${classes["itemcontainer"]} a[href="/"]`);
      });
    }
  }, [location]);

  return (
    <React.Fragment>
      <div className={classes["sidebarContainer"]} style={{ margin: "-2px" }}>
        <span className={classes["logogroup"]}>
          <FormattedMessage
            id="home.sideBar.drive"
            defaultMessage="Question Bank"
          />
        </span>

        <div className={classes["userinfogroup"]}>
          <span className={classes["userprofilepic"]}>
            <div>
              <img
                id="preview"
                src={
                  imageUrl.length <= 0
                    ? generateRandomImageRef.current
                    : imageUrl
                }
                alt="profile-pic"
                data-toggle="tooltip"
                data-placement="bottom"
                data-html={true}
                title={`<h6>${user.name}</h6>`}
              />
            </div>
          </span>

          {connectedUsers.length > 0 &&
            connectedUsers.map((user) => {
              const randomImage = createImageFromInitials(
                500,
                user.name,
                getBackgroundColor(user.roleId)
              );
              return (
                <span className={classes["userprofilepic"]} key={user.id}>
                  <div>
                    <img
                      id="preview"
                      src={imageUrl.length <= 0 ? randomImage : imageUrl}
                      alt="profile-pic"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      data-html={true}
                      title={`<h6>${user.name}</h6>`}
                    />
                  </div>
                </span>
              );
            })}
          {/* <img src={userprofileicon} alt="" /> */}

          {/*<span className={classes["userdetails"]}>
            <div className={classes["name"]}>{user.name ? user.name : ""}</div>
            <div className={classes["title"]}>
              <FormattedMessage
                id={`home.sideBar.${user.roleId?.name}`}
                defaultMessage="User"
              />
            </div>
          </span>8*/}
        </div>

        <div className={classes["sidebarItems"]}>
          <span className={classes["itemcontainer"]}>
            <Link to={ROUTE_HOME}>
              <img src={homeicon} alt="" />
              <span>
                <FormattedMessage id="home.home" defaultMessage="Home" />
              </span>
            </Link>
          </span>

          <RBAC allowedRoles={[ROLES.ORG_MEMBER, ROLES.ORG_ADMIN]}>
            <span className={classes["itemcontainer"]}>
              <Link to={`${ROUTE_GROUPS}/${organizationIdRef.current}`}>
                <img src={clienticon} alt="client icon" />
                <span>
                  <FormattedMessage
                    id="home.sideBar.clients"
                    defaultMessage="Clients"
                  />
                </span>
              </Link>
            </span>
          </RBAC>

          <span className={classes["itemcontainer"]}>
            <Link to={ROUTE_QUESTIONNAIRE}>
              <img
                src={tagicon}
                alt="tag icon"
                style={{
                  objectFit: "contain",
                  width: "25px",
                  marginLeft: "5px",
                  marginRight: "9px",
                }}
              />
              <span>
                <FormattedMessage
                  id="questionnaire"
                  defaultMessage="Questionnaire"
                />
              </span>
            </Link>
          </span>

          <RBAC allowedRoles={[ROLES.OFFICIAL_ADMIN]}>
            <span className={classes["itemcontainer"]}>
              <Link to={ROUTE_TAGS}>
                <img
                  src={tagicon}
                  alt="tag icon"
                  style={{
                    objectFit: "contain",
                    width: "25px",
                    marginLeft: "5px",
                    marginRight: "9px",
                  }}
                />
                <span>
                  <FormattedMessage
                    id="tagManagement"
                    defaultMessage="Tag Management"
                  />
                </span>
              </Link>
            </span>
          </RBAC>

          <span className={classes["itemcontainer"]}>
            <Link to={ROUTE_USERMANAGEMENT}>
              <img src={usericon} alt="user icon" />
              <span>
                <FormattedMessage
                  id="home.sideBar.userManagement"
                  defaultMessage="User Management"
                />
              </span>
            </Link>
          </span>
          <span className={classes["itemcontainer"]}>
            <Link className={classes["bin"]} to={ROUTE_BIN}>
              <img src={deleteicon} alt="" />
              <span>
                <FormattedMessage id="trash" defaultMessage="Trash" />
              </span>
            </Link>
          </span>
          {/*<span className={classes["itemcontainer"]} style={disabledStyle}>
            <Link to={""}>
              <img src={settingsicon} alt="" />
              <span style={disabledStyle}>
                <FormattedMessage
                  id="home.sideBar.settings"
                  defaultMessage="Settings"
                />
              </span>
            </Link>
        </span>*/}

          <div className="form-group col-sm-10 mt-4 ml-3">
            <select
              className={classes["language-select"]}
              value={locale}
              onChange={(e) => selectLanguage(e, user)}
            >
              <option value="en-US">English</option>
              <option value="zh-CN">繁體中文</option>
            </select>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
