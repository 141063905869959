import React from "react";
import ReactDOM from "react-dom";
import { Dialog } from "evergreen-ui";
import { LEAVEMODAL } from "../../enums.js";
const UserConfirmation = (message, callback) => {
  let lang = JSON.parse(localStorage.getItem("user")).language;

  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);
  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };
  ReactDOM.render(
    <Dialog
      cancelLabel={lang === "en-US" ? "Cancel" : LEAVEMODAL.CANCEL}
      confirmLabel={lang === "en-US" ? "Leave" : LEAVEMODAL.LEAVE}
      isShown={true}
      onCacel={() => closeModal(false)}
      onConfirm={() => closeModal(true)}
      title={
        lang === "en-US" ? "Confirm leaving the page?" : LEAVEMODAL.WARNING
      }
    >
      {lang === "en-US"
        ? message === "questionSet"
          ? LEAVEMODAL.QUESTIONSET_BODY
          : LEAVEMODAL.FOLDER_BODY
        : message === "questionSet"
          ? LEAVEMODAL.QUESTIONSET_BODY_CHINESE
          : LEAVEMODAL.FOLDER_BODY_CHINESE}
    </Dialog>,
    container
  );
};
export default UserConfirmation;
