import { createContext } from "react";

const defaultValues = {
  audioLibraryContent: {},
  setAudioLibraryContent: () => {},
  audioSelectedNumber: null,
  setAudioSelectedNumber: () => {},
};

export const AudioContext = createContext(defaultValues);
export const AudioContextProvider = AudioContext.Provider;
export const AudioContextConsumer = AudioContext.Consumer;
