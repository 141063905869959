import API from "../api/Api";
import { USERS } from "../api/endpoints";

export const updateUser = async (id, language) => {
  const api = new API();

  const response = await api.patch(`${USERS}/${id}`, {
    language,
  });

  return response.data.data;
};
