import { ROLES } from "../enums";
import { COLORS } from "../enums";
export function setCaretPosition(ctrl, pos) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

    // IE8 and below
  } else if (ctrl.createTextRange) {
    var range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd("character", pos);
    range.moveStart("character", pos);
    range.select();
  }
}

export const allowedImageFiles = () => {
  return "image/x-png,image/jpeg";
};

export const allowedAudioFiles = () => {
  return "audio/mp3";
};

export const generateDateAndTime = (updated) => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999);
  const updatedDate = new Date(parseInt(updated));

  if (
    updatedDate.getTime() >= todayStart.getTime() &&
    updatedDate.getTime() < todayEnd.getTime()
  ) {
    return new Date(parseInt(updated)).toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      formatMatcher: "basic",
    });
  } else {
    return new Date(parseInt(updated)).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      formatMatcher: "basic",
    });
  }
};

export const deletedOptionsRange = (newValue, existingValue) => {
  const diff = existingValue - newValue;
  return existingValue - (diff - 1);
};

export const createImageFromInitials = (size, name, color) => {
  if (name === null) return;
  name = getInitials(name);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context.fillStyle = COLORS.WHITE;
  context.fillRect(0, 0, size, size);

  context.fillStyle = color;
  context.fillRect(0, 0, size, size);

  context.fillStyle = COLORS.WHITE;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};

export const getBackgroundColor = ({ name }) => {
  switch (name) {
    case ROLES.OFFICIAL_ADMIN:
      return COLORS.MANDY;
    case ROLES.ORG_ADMIN:
      return COLORS.DODGER_BLUE;
    default:
      return COLORS.CHATEAU_GREEN;
  }
};

const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const checkDeletedOptionsInQuestions = (removedOptions, questions) => {
  if (removedOptions.length > 0) {
    const isValid = removedOptions.some(({ answer }) => {
      return questions.some((question) => {
        return (
          question.correctAnswer === answer ||
          question.inCorrectAnswer === answer
        );
      });
    });

    return isValid;
  }
};

// export const roleBaseRedirect = (user) => {
//   const redirectRouteOptions = {
//     [ROLES.OFFICIAL_ADMIN]: ADMIN_ROUTE_HOME,
//     [ROLES.ORG_ADMIN]: ROUTE_HOME,
//     [ROLES.ORG_MEMBER]: ROUTE_HOME,
//   };

//   const { name } = user.roleId;
//   return redirectRouteOptions[name];
// };
