import React, { useContext } from "react";
import classes from "./navbar.module.scss";
import logouticon from "../../assets/images/Logout.png";
import sidebaricon from "../../assets/images/Sidebar.png";
import { AuthContext } from "../../contexts/auth/authContext";

const Navbar = ({ setSidebarVisibility, isAuthenticated, children }) => {
  const authContext = useContext(AuthContext);
  return (
    <React.Fragment>
      <div className={`${classes["navcontainer"]}`}>
        {isAuthenticated && (
          <React.Fragment>
            <img
              src={sidebaricon}
              className={`${classes["sidebaricon"]}`}
              alt=""
              onClick={setSidebarVisibility}
            />

            <img
              src={logouticon}
              className={`${classes["logouticon"]}`}
              alt=""
              onClick={authContext.logout}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Navbar;
