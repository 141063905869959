/** @format */

export const QUESTION_SET_STATUS = Object.freeze({
  PUBLISHED: "Published",
  NOT_PUBLISHED: "Not Published",
  TO_PUBLISH: "To Publish",
});

export const LANGAUGES = Object.freeze({
  ENGLISH: "en-US",
  CHINESE: "zh-CN",
  REGEX_CHINESE:
    /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u,
});

export const DROPIFYMESSAGES = Object.freeze({
  THUMBNAILCHINESE: "將文件拖放到此處或點擊開始選擇",
  ANSWERAUDIOCHINESE: "拖放或點擊所需上傳的檔案",
  THUMBNAILENGLISH: "Drag a file here or click to get started",
  ANSWERAUDIOENGLISH: "Drag a file or click to select",
});

export const LEAVEMODAL = Object.freeze({
  CANCEL: "取消",
  LEAVE: "離開",
  WARNING: "確定要離開頁面？",
  QUESTIONSET_BODY_CHINESE:
    "確定要離開頁面？你目前在編輯問題組的資料，如果離開頁面，所有新加入的圖片，音效檔案，文字會被丟棄 請點擊［儲存］或［發佈］保存最新的編輯。",
  FOLDER_BODY_CHINESE:
    "您正在編輯文件夾集的詳細信息。如果繼續保留，則新上傳的圖像，音頻文件或文本將被丟棄 如果您要保留更改，請單擊頁面上的完成。",
  QUESTIONSET_BODY:
    "You are editing the details of a question set. If continue to leave, newly uploaded images, audio files or text will be discarded. Please click Save / Publish on the page if you intend to keep the changes.",
  FOLDER_BODY:
    "You are editing the details of a folder set. If continue to leave, newly uploaded images, audio files or text will be discarded. Please click Done on the page if you intend to keep the changes.",
});

export const ROLES = Object.freeze({
  OFFICIAL_ADMIN: "Official Admin",
  ORG_ADMIN: "Organization Admin",
  ORG_MEMBER: "Organization Member",
});

export const PERMISSIONS = Object.freeze({
  EDITOR: "Editor",
  VIEWER: "Viewer",
  OFF: "OFF",
});

export const GROUP = Object.freeze({
  OFFICIAL: "Official",
  ORGANIZATION: "Organization",
});

export const COLORS = Object.freeze({
  WHITE: "#FFFFFF",
  MANDY: "#E84855",
  DODGER_BLUE: "#3185FC",
  CHATEAU_GREEN: "#48B265",
  AZURE_RADIANCE: "#0087FD",
  PICKLED_BLUEWOOD: "#2F4050",
});
export const LIBRARY = Object.freeze({
  LIBRARY_GROUP_IMAGE: "Image",
  LIBRARY_GROUP_AUDIO: "Audio",
  LIBRARY_IMAGE_TITLE: "Image Library",
  LIBRARY_AUDIO_TITLE: "Audio Library",
});
export const TILE_CARDS_HEADER_TITLES = Object.freeze({
  ACTION_CARD_FOLDERS: "Folders",
  ACTION_CARD_QUESTION_SETS: "Question Sets",
  ACTION_CARD_RESOURCE_LIBRARY: "Resource Library",
  ACTION_CARD_GROUP: "Groups",
  ACTION_CARD_QUESTIONNAIRE: "Questionnaire",
});

export const TILE_CARDS_FOOTER_TITLES = Object.freeze({
  ACTION_CARD_FOLDERS: "new folder",
  ACTION_CARD_QUESTION_SETS: "new set",
  ACTION_CARD_RESOURCE_LIBRARY: "open",
  ACTION_CARD_GROUP: "New Group",
  ACTION_CARD_QUESTIONNAIRE: "New",
});

export const RESOURCE_LIBRARY_TYPE = Object.freeze({
  TYPE_IMAGE: "Image",
  TYPE_AUDIO: "Audio",
  ALLOWED_TYPES_IMAGE: ["image/jpeg", "image/png"],
  ALLOWED_TYPES_AUDIO: ["audio/mpeg", "video/quicktime"],
});
export const USER_ROLE = Object.freeze({
  OFFICIAL_ADMIN: "Official Admin",
  ORGANIZATION_MEMBER: "Organization Member",
});

export const TOGGLER_STATE = Object.freeze({
  RESOURCE: "resource",
  UPLOAD: "upload",
  ORIGINAL: "original",
  SQUARE: "square",
  RECTANGLE: "rectangle",
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  POSITIONS: ["left", "center", "right"],
});

export const PUSHER_FOLDER_EVENTS = Object.freeze({
  INSERTED: "folder-created",
  UPDATED_NAME: "folder-name-updated",
  DELETED: "folder-deleted",
});

export const PUSHER_QUESTION_SET_EVENTS = Object.freeze({
  INSERTED: "question-set-created",
  DELETED: "question-set-deleted",
  UPDATED: "question-set-updated",
});

export const ENVIRONMENTS = Object.freeze({
  LOCAL: "local",
  DEV: "dev",
});

export const PUSHER_ACTION_TYPES = Object.freeze({
  ADD_MEMBER: "ADD_MEMBER",
  REMOVE_MEMBER: "REMOVE_MEMBER",
  NO_MEMBER: "NO_MEMBER",
});

export const QUESTION_PREVIEW_IMAGE = Object.freeze({
  QUESTION_TEXT: "On cloud",
  SUPPLEMENTARY_TEXT: "Meaning: Very Happy",
  CORRECT_ANSWER: "One",
  INCORRECT_ANSWER: "Eight, Nine, Eleven, Twelve",
});

export const QUESTION_PREVIEW_IMAGE_CHINESE = Object.freeze({
  QUESTION_TEXT: "(四）通八達",
  SUPPLEMENTARY_TEXT: "意思：形容交通便利",
  CORRECT_ANSWER: "五",
  INCORRECT_ANSWER: "四, 一, 二, 五",
});

export const CROPPER_ASPECT_RATIO = Object.freeze({
  SQUARE: "square",
  RECTANGLE: "rectangle",
  ORIGINAL: "original",
});

export const GROUP_NAME = Object.freeze({
  OFFICIAL_CONTENT: "Official Content",
});
export const ORGANIZATION_TYPES = Object.freeze({
  TYPE1: "Type 1",
  TYPE2: "Type 2",
  TYPE3: "Type 3",
  TYPE4: "Type 4",
});
export const ORGANIZATION_REGIONS = Object.freeze({
  HONG_KONG: "Hong Kong",
  CHINA: "China",
  UNITED_STATES: "United States",
});

export const QUESTION_TYPES = Object.freeze({
  GENERAL: "General",
  MATHLOGIC: "Math & Logic",
});

export const MATH_LAYOUTS_CATEGORIES = Object.freeze({
  OBSERVE: "Observe",
  COUNTING: "Counting",
  ARITHMATIC: "Arithmatic",
  SEQUENCE: "Sequence",
  ADVANCEDCOGNITIVE: "Advanced Cognitive",
});

export const QUESTIONTEXT_TOGGLER = Object.freeze({
  SIMILAR: "similar",
  DIFFERENT: "different",
});
export const QUESTION_PAIRS_LABEL = Object.freeze({
  MAIN: "Main",
  SUB: "Sub",
});
export const QUESTION_ARITHMETIC_OPERATORS = Object.freeze({
  ADDITION: "Addition",
  SUBTRACTION: "Subtraction",
  MULTIPLICATION: "Multiplication",
  DIVIDE: "Division",
});
export const ADVANCE_COGNITIVE_TOGGLE_LABEL = Object.freeze({
  TRUE: "True",
  FALSE: "False",
});
