export const BASE_URL_LOCAL = "http://localhost:8000/api/";
export const BASE_URL_DEV = "https://question-bank-mvp.herokuapp.com/api/";
export const BASE_URL_PROD =
  "https://question-bank-production.herokuapp.com/api/";
export const LOGIN = "auth";
export const STATS = "stats";
export const FOLDERS = "folders";
export const QUESTION_SETS = "questionSets";
export const LAYOUTS = "layouts";
export const TAGS = "tags";
export const QUESTION_IMAGES = "questionImages";
export const USERS = "users";
export const PERMISSIONS = "permissions";
export const LIBRARY_ENDPOINT = "library";
export const GROUPS = "/organization/groups";
export const CLIENTS = "/organization/group/clients";
export const QUESTIONNAIRE = "/questionnaire";

// export const MYASSESSMENTPDFCOHORT = (datafrom: any, dateto: any, id: any) =>
//   `Reports/CohortReport/${id}/${datafrom}/${dateto}`;

// http://stage-evaluskills.peregrineacademics.com/assessments/report/ca5863e0-3597-4d2a-9b35-aae7ded8cd80/detail
