import { createContext } from "react";

const defaultValues = {
  selectedStepNumber: null,
  setSelectedStepNumber: () => {},
  selectedQuestionNumber: null,
  setSelectedQuestionNumber: () => {},
  setStepId: () => {},
  stepId: null,
  itemNumber: null,
  setItemNumber: () => {},
};

export const QuestionStepContext = createContext(defaultValues);
export const QuestionStepContextProvider = QuestionStepContext.Provider;
export const QuestionStepContextConsumer = QuestionStepContext.Consumer;
