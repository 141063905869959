import { Route, Switch, Redirect } from "react-router";
import React, { Suspense, useContext } from "react";
import Spinner from "../../components/spinner/spinner";
import {
  ROUTE_HOME,
  ROUTE_BIN,
  ROUTE_USERMANAGEMENT,
  ROUTE_SETTINGS,
  ROUTE_ADDQUESTIONSET,
  ROUTE_ADDQUESTIONSET_ID,
  ROUTE_LOGIN,
  ROUTE_CREATE_FOLDER,
  ROUTE_EDIT_FOLDER_ID,
  ROUTE_EDITQUESTIONSET_ID,
  ROUTE_ORGANIZATION_CONTENT_ID,
  ROUTE_ADDORGANIZATION,
  ROUTE_ADDMEMBER,
  ROUTE_EDITORGANIZATION_ID,
  ROUTE_EDITMEMBER_ID,
  ROUTE_OFFICIAL_CONTENT,
  ROUTE_LIBRARY_CONTENT_ID,
  ROUTE_LIBRARY_ID,
  ROUTE_EDITQUESTIONSET,
  ROUTE_GROUPS_ID,
  ROUTE_ADD_GROUP_ID,
  ROUTE_ADD_CLIENT_ID,
  ROUTE_EDIT_GROUP_ID,
  ROUTE_EDIT_CLIENT_ID,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_OF_USE,
  ROUTE_TAGS,
  ROUTE_QUESTIONNAIRE_ACTION,
  ROUTE_QUESTIONNAIRE,
  ROUTE_QUESTIONNAIRE_REPORT,
  ROUTE_QUESTIONNAIRE_REPORT_RESULT,
} from "../../constants";
import { AuthContext } from "../../contexts/auth/authContext";
import { ROLES } from "../../enums";

import PrivateRoute from "../../components/privateRoute/privateRoute";

// const HomeContainer = React.lazy(() => import("../home/home"));
const FolderContainer = React.lazy(() =>
  import("../folderContainer/FolderContainer")
);
const BinContainer = React.lazy(() => import("../bin/bin"));
const EditQuestionSetContainer = React.lazy(() =>
  import("../../containers/questionSet/EditQuestionSetContainer")
);
const LoginContainer = React.lazy(() =>
  import("../../components/login/LoginContainer")
);

const SettingsContainer = React.lazy(() => import("../settings/settings"));
// const UserManagementContainer = React.lazy(() =>
//   import("../usermanagement/usermanagement")
// );

const QuestionSetContainer = React.lazy(() =>
  import("../questionSet/questionSet")
);

const HomeContainer = React.lazy(() =>
  import("../homeContainer/HomeContainer")
);

const OrganizationContainer = React.lazy(() =>
  import("../organizationContainer/OrganizationContainer")
);

const ClientContainer = React.lazy(() =>
  import("../clientContainer/ClientContainer")
);

const UserManagementContainer = React.lazy(() =>
  import("../userManagementContainer/UserManagementContainer")
);

const NewOrganizationContainer = React.lazy(() =>
  import("../newOrganizationContainer/NewOrganizationContainer")
);

const NewMemberContainer = React.lazy(() =>
  import("../newMemberContainer/NewMemberContainer")
);

const EditOrganizationContainer = React.lazy(() =>
  import("../editOrganizationContainer/EditOrganizationContainer")
);

const EditMemberContainer = React.lazy(() =>
  import("../editMemberContainer/EditMemberContainer")
);
const LibraryContainer = React.lazy(() =>
  import("../libraryContainer/LibraryContainer")
);
const ImageLibraryContainer = React.lazy(() =>
  import("../libraryContentContainer/LibraryContentContainer")
);

const AddGroupContainer = React.lazy(() =>
  import("../addGroupContainer/AddGroupContainer")
);

const AddGroupClient = React.lazy(() =>
  import("../addGroupClient/AddGroupClient")
);

const EditGroupContainer = React.lazy(() =>
  import("../editGroupContainer/EditGroupContainer")
);

const EditClientContainer = React.lazy(() =>
  import("../editClientContainer/EditClientContainer")
);
const PrivacyPolicy = React.lazy(() =>
  import("../privacyPolicy/PrivacyPolicy")
);
const TermsOfUse = React.lazy(() => import("../termsOfUse/TermsOfUse"));
const TagContainer = React.lazy(() => import("../tagContainer/TagContainer"));
const QuestionnaireForm = React.lazy(() =>
  import(
    "../questionnaireContainer/QuestionnaireForm/QuestionnaireFormContainer"
  )
);
const QuestionnaireList = React.lazy(() =>
  import("../questionnaireContainer/QuestionnaireList")
);
const QuestionnaireReport = React.lazy(() =>
  import("../questionnaireContainer/QuestionnaireReport/QuestionnaireReport")
);
const QuestionnaireReportCard = React.lazy(() =>
  import(
    "../questionnaireContainer/QuestionnaireReportCard/QuestionnaireReportCard"
  )
);

const Routes = () => {
  return (
    <Suspense fallback={<Spinner lightBg={true} />}>
      <Switch>
        <PrivateRoute
          path={ROUTE_HOME}
          exact={true}
          component={HomeContainer}
        />
        <PrivateRoute
          path={ROUTE_ORGANIZATION_CONTENT_ID}
          exact={true}
          component={OrganizationContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_OFFICIAL_CONTENT}
          exact={true}
          component={OrganizationContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_USERMANAGEMENT}
          exact={true}
          component={UserManagementContainer}
        />
        <PrivateRoute
          path={ROUTE_ADDORGANIZATION}
          exact={true}
          component={NewOrganizationContainer}
        />
        <PrivateRoute
          path={ROUTE_ADDMEMBER}
          exact={true}
          component={NewMemberContainer}
        />
        <PrivateRoute
          path={ROUTE_EDITORGANIZATION_ID}
          exact={true}
          component={EditOrganizationContainer}
        />
        <PrivateRoute
          path={ROUTE_EDITMEMBER_ID}
          exact={true}
          component={EditMemberContainer}
        />
        <PrivateRoute
          path={ROUTE_BIN}
          exact={true}
          component={BinContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_GROUPS_ID}
          exact={true}
          component={ClientContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_USERMANAGEMENT}
          exact={true}
          component={UserManagementContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_SETTINGS}
          exact={true}
          component={SettingsContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_ADDQUESTIONSET}
          exact={true}
          component={QuestionSetContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_ADDQUESTIONSET_ID}
          exact={true}
          component={QuestionSetContainer}
        ></PrivateRoute>
        <Route
          path={ROUTE_LOGIN}
          exact={true}
          component={LoginContainer}
        ></Route>
        <PrivateRoute
          path={ROUTE_CREATE_FOLDER}
          exact={true}
          component={FolderContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_EDIT_FOLDER_ID}
          exact={true}
          component={FolderContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_EDITQUESTIONSET_ID}
          exact={true}
          component={EditQuestionSetContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_EDITQUESTIONSET}
          exact={true}
          component={EditQuestionSetContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_LIBRARY_ID}
          exact={true}
          component={LibraryContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_LIBRARY_CONTENT_ID}
          exact={true}
          component={ImageLibraryContainer}
        ></PrivateRoute>

        <PrivateRoute
          path={ROUTE_ADD_GROUP_ID}
          exact={true}
          component={AddGroupContainer}
        />
        <PrivateRoute
          path={ROUTE_ADD_CLIENT_ID}
          exact={true}
          component={AddGroupClient}
        />
        <PrivateRoute
          path={ROUTE_EDIT_GROUP_ID}
          exact={true}
          component={EditGroupContainer}
        />
        <PrivateRoute
          path={ROUTE_EDIT_CLIENT_ID}
          exact={true}
          component={EditClientContainer}
        />
        <PrivateRoute path={ROUTE_TAGS} exact={true} component={TagContainer} />
        <PrivateRoute
          path={ROUTE_QUESTIONNAIRE_ACTION}
          exact={true}
          component={QuestionnaireForm}
        />
        <PrivateRoute
          path={ROUTE_QUESTIONNAIRE}
          exact={true}
          component={QuestionnaireList}
        />
        <PrivateRoute
          path={ROUTE_QUESTIONNAIRE_REPORT}
          exact={true}
          component={QuestionnaireReport}
        />
        <Route
          path={ROUTE_QUESTIONNAIRE_REPORT_RESULT}
          exact={true}
          component={QuestionnaireReportCard}
        ></Route>
        <Route
          path={ROUTE_PRIVACY_POLICY}
          exact={true}
          component={PrivacyPolicy}
        ></Route>
        <Route
          path={ROUTE_TERMS_OF_USE}
          exact={true}
          component={TermsOfUse}
        ></Route>

        <Route
          path={ROUTE_LOGIN}
          exact={true}
          component={LoginContainer}
        ></Route>
        <Redirect from="*" to={ROUTE_LOGIN} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
