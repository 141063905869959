import React, { useState } from "react";
import { AudioContextProvider } from "./audioContext";

const AudioContextContainer = ({ children }) => {
  const [audioLibraryContent, setAudioLibraryContent] = useState({});
  const [audioSelectedNumber, setAudioSelectedNumber] = useState(null);
  return (
    <AudioContextProvider
      value={{
        audioLibraryContent,
        setAudioLibraryContent,
        audioSelectedNumber,
        setAudioSelectedNumber,
      }}
    >
      {children}
    </AudioContextProvider>
  );
};

export default AudioContextContainer;
