import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import { withRouter } from "react-router";
import { ROUTE_HOME } from "../../constants";
import { AuthContextProvider } from "./authContext";
import { LanguagesContext } from "../languagesContext/languageContext";
import English from "../../languages/en.json";
import Chinese from "../../languages/zh.json";
import { LANGAUGES, PUSHER_ACTION_TYPES } from "../../enums";

// import NavBarContext from '../Dashboard/context';

const connectedUsersReducer = (state, action) => {
  switch (action.type) {
    case PUSHER_ACTION_TYPES.ADD_MEMBER:
      return [...state, { id: action.payload.id, ...action.payload.info }];
    case PUSHER_ACTION_TYPES.REMOVE_MEMBER:
      const users = [...state];
      return users.filter((user) => user.id.toString() !== action.payload.id);
    case PUSHER_ACTION_TYPES.NO_MEMBER:
      return [];
    default:
      return state;
  }
};

const AuthContextContainer = ({ history, children }) => {
  // const { onHiddenNavChange } = useContext(NavBarContext);
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthentication());
  const { setLocale, setMessages } = useContext(LanguagesContext);
  const [connectedUsers, dispatch] = useReducer(connectedUsersReducer, []);
  const [sidebarVisibility, setSidebarVisibility] = useState(true);

  const setUserMessages = useCallback(
    (language) => {
      if (language === LANGAUGES.ENGLISH) {
        setMessages(English);
      } else if (language === LANGAUGES.CHINESE) {
        setMessages(Chinese);
      }
    },
    [setMessages]
  );

  useEffect(() => {
    if (isAuthenticated) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.language) {
        setLocale(user.language);
        setUserMessages(user.language);
      }
    } else {
      const browserLanguage = navigator.language;
      if (browserLanguage) {
        setLocale(browserLanguage);
        setUserMessages(browserLanguage);
      }
    }
  }, [isAuthenticated, setLocale, setUserMessages]);

  function checkAuthentication() {
    return (
      !!localStorage.getItem("user") && !!window.sessionStorage.getItem("token")
    );
  }

  const getUser = useCallback(() => {
    const user = localStorage.getItem("user");
    // console.log('user1', user);
    try {
      return JSON.parse(user);
    } catch (err) {
      return {};
    }
  }, []);
  const getToken = useCallback(() => {
    const token = window.sessionStorage.getItem("token");
    try {
      return token;
    } catch (err) {
      return {};
    }
  }, []);

  const authenticate = (token, user) => {
    // const authDetails = JSON.parse(user);
    window.sessionStorage.setItem("token", token);

    localStorage.setItem("user", user);
    // localStorage.setItem('token', token);
    setIsAuthenticated(true);

    history.push(ROUTE_HOME);
  };

  const logout = () => {
    localStorage.removeItem("user");
    window.sessionStorage.removeItem("token");
    setIsAuthenticated(false);
    dispatch({
      type: PUSHER_ACTION_TYPES.NO_MEMBER,
    });
    // onHiddenNavChange(true);
    // history.push(ROUTE_HOME);
  };

  return (
    <AuthContextProvider
      value={{
        isAuthenticated: isAuthenticated,
        authenticate,
        logout,
        checkAuthentication,
        getUser,
        getToken,
        dispatch,
        connectedUsers,
        setSidebarVisibility,
        sidebarVisibility,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};

export default withRouter(AuthContextContainer);
