import React, { useContext } from "react";
import { Route, Redirect } from "react-router";
import { ROUTE_LOGIN } from "../../constants";
import { AuthContext } from "../../contexts/auth/authContext";

const PrivateRoute = ({ component, ...rest }) => {
  const authContext = useContext(AuthContext);

  return (
    <React.Fragment>
      {authContext.checkAuthentication() ? (
        <Route {...rest} exact={true} component={component} />
      ) : (
        <Redirect to={ROUTE_LOGIN} />
      )}
    </React.Fragment>
  );
};

export default PrivateRoute;
