// Routes Constant

export const ROUTE_HOME = "/";
export const ROUTE_BIN = "/bin";
export const ROUTE_LOGIN = "/login";
export const ROUTE_USERMANAGEMENT = "/usermanagement";
export const ROUTE_GROUPS_ID = "/groups/:organizationId";
export const ROUTE_GROUPS = "/groups";
export const ROUTE_ADD_GROUP = "/add/group";
export const ROUTE_ADD_GROUP_ID = "/add/group/:organizationId";
export const ROUTE_ADD_CLIENT = "/add/client";
export const ROUTE_ADD_CLIENT_ID = "/add/client/:groupId";
export const ROUTE_EDIT_CLIENT = "/edit/client";
export const ROUTE_EDIT_CLIENT_ID = "/edit/client/:clientId";
export const ROUTE_EDIT_GROUP = "/edit/group";
export const ROUTE_EDIT_GROUP_ID = "/edit/group/:groupId";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_ADDQUESTIONSET = "/questionset/add";
export const ROUTE_ADDQUESTIONSET_ID = "/questionset/add/:organizationId";
export const ROUTE_EDITQUESTIONSET = "/questionset/edit";
export const ROUTE_EDITQUESTIONSET_ID = "/questionset/edit/:organizationId";
export const ROUTE_CREATE_FOLDER = "/folder/add";
export const ROUTE_EDIT_FOLDER = "/folder/edit";
export const ROUTE_EDIT_FOLDER_ID = "/folder/edit/:id";
export const ROUTE_ORGANIZATION_CONTENT = "/organization";
export const ROUTE_ORGANIZATION_CONTENT_ID = "/organization/:id";
export const ROUTE_OFFICIAL_CONTENT = "/official/content";
export const ROUTE_ADDORGANIZATION = "/organizations/add";
export const ROUTE_EDITORGANIZATION_ID = "/organizations/edit/:id";
export const ROUTE_EDITORGANIZATION = "/organizations/edit";
export const ROUTE_ADDMEMBER = "/member/add";
export const ROUTE_EDITMEMBER = "/member/edit";
export const ROUTE_EDITMEMBER_ID = "/member/edit/:id";
export const ROUTE_LIBRARY = "/library";
export const ROUTE_LIBRARY_CONTENT_ID =
  "/library/folder/content/:organizationId";
export const ROUTE_LIBRARY_CONTENT = "/library/folder/content";
export const ROUTE_LIBRARY_ID = "/library/:organizationId/";
export const ROUTE_AUDIO_LIBRARY = "/library/audio";
export const ROUTE_PRIVACY_POLICY = "/legal/privacy";
export const ROUTE_TERMS_OF_USE = "/legal/tos";
export const ROUTE_TAGS = "/tags";
export const ROUTE_QUESTIONNAIRE = "/questionnaire";
export const ROUTE_QUESTIONNAIRE_REPORT_RESULT =
  "/questionnaire/report/:submissionId/result";
export const ROUTE_QUESTIONNAIRE_REPORT =
  "/questionnaire/report/:questionnaireId";
export const ROUTE_QUESTIONNAIRE_ACTION = "/questionnaire/:action";
export const PAGE_SIZE_LIST = [5, 10, 15, 20];
export const TOTAL_PAGES = 5;

// Colors Constants

export const ALLOWED_IMAGE_FILES = ["image/png", "image/jpeg", "image/jpg"];
export const ALLOWED_AUDIO_FILES = ["audio/mp3", "audio/mpeg", "audio/mov"];

//folder collapse
export const COLLAPSE_STATUS = "COLLAPSE_STATUS";

export const SORT_FIELD = "SORT_FIELD";
