import React, { useState, useContext, useCallback } from "react";

import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import { AuthContext } from "../../contexts/auth/authContext";
import Routes from "../routes/routes";
import classes from "./appContainer.module.scss";

const AppContainer = () => {
  const authContext = useContext(AuthContext);
  const { sidebarVisibility, setSidebarVisibility } = authContext;

  const toggleSidebar = useCallback(() => {
    setSidebarVisibility((prev) => !prev);
  }, []);

  return (
    <div className={classes["appcontainer"]}>
      <div className="row h-100">
        {authContext.checkAuthentication() && sidebarVisibility && (
          <div className="col-2">
            {" "}
            <Sidebar />
          </div>
        )}
        <div
          className={`${
            sidebarVisibility && authContext.checkAuthentication()
              ? "col-10"
              : "col-12"
          } main customnavbar`}
        >
          <Navbar
            isAuthenticated={authContext.checkAuthentication()}
            setSidebarVisibility={toggleSidebar}
          ></Navbar>

          <div className={classes["homebody"]}>
            <Routes />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppContainer;

// <BrowserRouter>
//   <div className={"container" + classes["appcontainer"]}>
//     <div className="row">
//       <div className="col-2">
//         <Sidebar />
//       </div>
//       <div className={"col-10"}>
//         <Navbar>{/* <Routes /> */}</Navbar>
//       </div>
//     </div>
//   </div>
// </BrowserRouter>
