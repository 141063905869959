import React, { useState } from "react";
import { QuestionStepContextProvider } from "./questionStepContext";

const QuestionStepContextContainer = ({ children }) => {
  const [selectedStepNumber, setSelectedStepNumber] = useState(null);
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(null);
  const [stepId, setStepId] = useState(null);
  const [itemNumber, setItemNumber] = useState(null);
  return (
    <QuestionStepContextProvider
      value={{
        selectedStepNumber,
        setSelectedStepNumber,
        setSelectedQuestionNumber,
        selectedQuestionNumber,
        setStepId,
        stepId,
        itemNumber,
        setItemNumber,
      }}
    >
      {children}
    </QuestionStepContextProvider>
  );
};

export default QuestionStepContextContainer;
